<template>
  <div class="main-box">
    <div class="main-title">新建保持器订单</div>
    <div class="jbxx">
      <div class="jbxx-title">基本信息</div>
      <el-form ref="form" :model="form" label-width="108px">
        <el-form-item class="huangzname jbxxform" label="患者姓名">
          <el-input
            class="bcq-input"
            v-model="form.name"
       
            maxlength="16"
            :disabled="binglft ? true : false"
          >
          </el-input>
        </el-form-item>

        <el-form-item class="jiuzyy jbxxform" label="就诊医院">
          <el-select v-model="form.leix" class="my-select">
            <el-option
              v-for="(item, i) in yiyshuzu"
              :key="i"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            @click="xzyybut"
            style="font-size: 16px; width: 110px; color: #1175d2"
            type="text"
            class="yiy-btn"
            >新增医院
          </el-button>
        </el-form-item>
        <el-form-item class="shdz jbxxform" label="收货地址">
          <el-select v-model="form.diz" class="my-select">
            <el-option
              v-for="(item, i) in dizshuzu"
              :key="i"
              :label="item.name"
              :value="item.id"
             
            >
            </el-option>
          </el-select>
          <el-button
            @click="xzdzbut"
            style="font-size: 16px; width: 110px; color: #1175d2"
            type="text"
            class="yiy-btn"
            >新增地址
          </el-button>
        </el-form-item>
      </el-form>
      <div class="item-box">
        <div class="title">口扫文件</div>
        <div>
          <div class="radio-box" @change="upChange">
            <el-radio v-model="radio" :label="1">上传数字口扫文件</el-radio>
            <el-radio v-model="radio" :label="2">寄出硅橡胶印模</el-radio>
          </div>

          <div class="detail" v-if="Number(radio) == 2">
            <div><span>*</span> 邮寄模型：</div>
            <div class="radio-div">
              <div
                :class="{ 'blue-bg': yjmx.includes(1) }"
                @click="yjmxChange(1)"
                style="padding: 0 15px"
              >
                硅橡胶印模
              </div>
              <div :class="{ 'blue-bg': yjmx.includes(2) }" @click="yjmxChange(2)">
                硅橡胶咬𬌗记录
              </div>
            </div>
          </div>
          <div class="detail" v-if="Number(radio) == 2 && yjmx.length > 0">
            <div><span>*</span> 邮寄方式：</div>
            <div class="radio-div">
              <div :class="{ 'blue-bg': mailType == 1 }" @click="getSFById()">
                预约取件
              </div>
              <div :class="{ 'blue-bg': mailType == 2 }" @click="orders((mailType = 2))">
                自行邮寄
              </div>
            </div>
          </div>
          <div class="scwj" v-if="Number(radio) == 1 && upType == 1">
            <div class="wenjscbox">
              <el-upload
                class="upload-demo"
                :action="baseURL"
                multiple
                ref="schuanwj"
                :on-change="handleSuccess"
                :on-remove="remove"
                :show-file-list="false"
                :file-list="fileList"
                :auto-upload="false"
                :data="{}"
              >
                <div class="btn">
                  <img :src="newImg" />
                  点击上传口扫文件，可选择多个文件批量上传
                </div>
              </el-upload>
              <div class="upFile" v-for="(item, index) in fileList" :key="index">
                <img src="@/assets/2.2病例详情/file.png" />
                <div class="fileName">{{ item.name }}</div>
                <div class="btn" @click="remove(item)">删除</div>
              </div>
            </div>
          </div>
          <div class="table-box" v-if="radio == 1 && upType == 2">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="upTime" label="上传时间" width="70">
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="70"> </el-table-column>
              <el-table-column prop="urlId" label="所属ID" width="204"> </el-table-column>
              <el-table-column width="56" align="right">
                <template slot="header" slot-scope="scope">
                  <div class="xuanze" @click="upFile">选择文件</div>
                </template>
                <template slot-scope="scope">
                  <div class="del" @click="delFn(scope.row.id)">删除</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="table-box" v-if="radio == 2 && mailType == 1">
            <div
              class="blue-btn"
              @click="(dialogVisible3 = true), (isEdit = false)"
              v-if="yjmx.length > 0"
            >
              寄快递
            </div>
            <div class="qjxl" v-if="jjxl.jjdz.address">
              <div class="title">{{ state }}</div>
              <div class="jjdz">
                <div><span>*</span>寄件地址：</div>
                <div style="width: 500px">
                  <p>{{ jjxl.jjdz.address }}</p>
                  <p>
                    {{ jjxl.jjdz.deliveryName }}<span class="space"> </span
                    >{{ jjxl.jjdz.contactNumber }}
                  </p>
                </div>
              </div>
              <div class="jjdz">
                <div>收件地址：</div>
                <div>
                  <p>{{ jjxl.sjdz.address }}</p>
                  <p>
                    {{ jjxl.sjdz.deliveryName }}<span class="space"> </span
                    >{{ jjxl.sjdz.contactNumber }}
                  </p>
                </div>
              </div>

              <div class="jjdz">
                <div><span>*</span>预约取件：</div>
                <div>
                  <p>{{ jjxl.yyqj }}</p>
                </div>
              </div>
              <div class="jjdz order-btn">
                <div @click="xgdj" class="active" v-if="edit != 1">修改订单</div>
                <div class="disabled" v-else>修改订单</div>
                <div @click="lxjsjy" class="active">联系收件员</div>
                <div @click="qxjj()" class="active" v-if="cancel != 1">取消寄件</div>
                <div class="disabled" v-else>取消寄件</div>
              </div>
            </div>
          </div>

          <div class="table-box" v-if="radio == 2 && mailType == 2">
            <div class="address">
              <div>收件地址：</div>
              <div class="detail-address">
                <div>{{ jjxl.sjdz.province + jjxl.sjdz.city + jjxl.sjdz.area }}</div>
                <div>{{ jjxl.sjdz.address }}</div>
                <div>{{ jjxl.sjdz.deliveryName + jjxl.sjdz.contactNumber }}</div>
              </div>
              <div class="copy-icon" @click="copyAddress">
                <img :src="imgCopy" alt="" />
              </div>
            </div>
          </div>

          <!-- 弹窗 -->

          <!-- 邮寄地址选择 -->
          <newsite @pushdizhishuju="pushdizhishuju" ref="tanchuangdizhi"></newsite>
          <!-- 寄快递 -->
          <el-dialog
            class="jkd"
            title="寄快递"
            :visible.sync="dialogVisible3"
            width="560px"
            :before-close="handleClose"
            center
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <div class="dialog-box express" @click="isQjsj = false">
              <div class="code">病例编号：{{ erpcaseno }}</div>
              <div class="text">邮寄硅橡胶模型时，请附病例编号一同寄出。</div>
              <div class="detail" style="flex-shrink: 0">
                <div><span>*</span> 寄件地址：</div>

                <div>
                  <div
                    @click="clickSelect()"
                    class="click-select"
                    :class="{ active: selectActive }"
                  >
                    {{ stringStr(dizAddress) }}
                  </div>
                  <el-select
                    ref="myselect"
                    v-model="diz"
                    @change="selectChange()"
                    @blur="selectChange()"
                    :disabled="isEdit"
                    popper-class="my-select"
                  >
                    <el-option
                      v-for="(item, i) in dizshuzu"
                      v-if="item.del != 1 || item.id == diz"
                      :key="i"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <!--   <el-button @click="xzdzbut" style="font-size:16px;width:110px;color:#1175d2;" type="text"
                            class="yiy-btn">新增地址
                        </el-button> -->
                </div>
                <div class="edit-box" @click="eidtAddres" v-if="!isEdit">
                  <img :src="imgEidt" /> 编辑地址信息
                </div>
              </div>
              <div class="detail">
                <div style="align-self: flex-start; flex-shrink: 0">收件地址：</div>

                <div>
                  <div style="width: 370px">
                    {{
                      jjxl.sjdz.province +
                      jjxl.sjdz.city +
                      jjxl.sjdz.area +
                      jjxl.sjdz.address
                    }}
                  </div>
                  <div>
                    {{ jjxl.sjdz.deliveryName }}<span class="space"></span
                    >{{ jjxl.sjdz.contactNumber }}
                  </div>
                </div>
              </div>
              <div class="detail">
                <div><span>*</span> 预约取件：</div>

                <div class="qjsj" :class="{ close: isQjsj }" @click.stop="isQjsj = true">
                  {{ qjsj.txt }}
                  <div class="qjsj-box" v-if="isQjsj">
                    <div class="qjsj-day">
                      <div :class="{ active: jt.checked }" @click="checkDay(jt)">
                        {{ jt.txt }}{{ jt.time }}
                      </div>
                      <div :class="{ active: mt.checked }" @click="checkDay(mt)">
                        {{ mt.txt }}{{ mt.time }}
                      </div>
                      <div :class="{ active: ht.checked }" @click="checkDay(ht)">
                        {{ ht.txt }}{{ ht.time }}
                      </div>
                    </div>
                    <div class="qjsj-line"></div>
                    <div class="qjsj-hour">
                      <div
                        v-for="item in hoursList"
                        @click.stop="checkHours(item)"
                        :class="{ active: item.checked }"
                      >
                        <p v-if="item.id != 0">
                          {{ item.start + "~" + item.end }}
                        </p>
                        <p v-if="item.id == 0 && nowH > 7">一小时内</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div class="submit" @click="submit(3, isEdit)">下单</div>
                <div class="canel" @click="canel(3)">取消</div>
              </div>
            </div>

            <!--   <span slot="footer" class="dialog-footer">
                <div class="submit" @click='submit(3)'>确定</div>
                <div class="canel" @click='canel(3)'>取消</div>
            </span> -->
          </el-dialog>
          <!-- 3shape -->
          <el-dialog
            title="3Shape"
            :visible.sync="dialogVisible"
            width="560"
            :before-close="handleClose"
            center
          >
            <div class="dialog-box">
              <div class="div-text">
                如没有您要选择的患者，请导出STL文件，选择本地上传
              </div>
              <div class="div-text">请选择口扫所在机构：</div>

              <el-select v-model="origin" placeholder="选择机构" class="sleect-box">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>

              <div class="div-text">请添加您的Communicate ID用于获取病例资料：</div>
              <el-input type="text" placeholder="请输入Communicate账号"></el-input>
              <p>Communicate ID查询路径：</p>
              <p>1. 打开3Shape TRIOS软件；</p>
              <p>2. 进入【配置 - 技工所连接 - 配置Communicate】；</p>
              <p>3. 登录名（电邮）即为Communicate ID。</p>
            </div>
            <span slot="footer" class="dialog-footer">
              <div class="submit" @click="submit(1)">确定</div>
              <div class="canel" @click="canel(1)">取消</div>
            </span>
          </el-dialog>
          <el-dialog
            title="3Shape"
            :visible.sync="dialogVisible2"
            width="560"
            :before-close="handleClose"
            center
          >
            <div>
              <div class="search-box">
                <el-input
                  placeholder="搜索患者姓名"
                  prefix-icon="el-icon-search"
                  v-model="search"
                >
                </el-input>
                <div class="btn" @click="bindId()">绑定ID管理</div>
              </div>
              <div class="table">
                <el-table
                  :data="tableData2"
                  style="width: 100%"
                  @row-click="rowClickEv"
                  :row-style="isRed"
                >
                  <el-table-column prop="upTime" label="上传时间" width="180">
                    <template slot-scope="scope">
                      <span class="img-box">
                        <img :src="imgUrl" v-if="scope.row.checked" />
                      </span>
                      {{ scope.row.upTime }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="姓名" width="180">
                  </el-table-column>
                  <el-table-column prop="urlId" label="所属ID"> </el-table-column>
                </el-table>
              </div>
            </div>

            <span slot="footer" class="dialog-footer">
              <div class="submit" @click="submit(2)">确定</div>
              <div class="canel" @click="canel(2)">取消</div>
            </span>
          </el-dialog>
          <el-dialog
            title="联系收件员"
            :visible.sync="dialogVisible5"
            width="200"
            :before-close="handleClose"
            center
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
              "
            >
              <span sytle="margin-right:10px;">电话</span> {{ empPhone }}
            </div>

            <span slot="footer" class="dialog-footer">
              <div class="submit" @click="dialogVisible5 = false">确定</div>
            </span>
          </el-dialog>
          <div class="show-box" v-if="isShow">
            <div class="confirm">
              <div class="top"></div>
              <div>
                当前已存在“
                <p>{{ strName.join(",") }}</p>
                ”文件请选择替换或保留两者
              </div>
              <div class="footer up-box">
                <div @click="tihuan">替换已有文件</div>
                <div @click="baoliu">保留两者</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="jbxx-title jgxx">保持器加工信息</div>
      <div class="item-box hegu">
        <div class="title">上颌数量</div>
        <input />
        <div class="title">下颌数量</div>
        <input />
      </div>
      <div class="item">
        <div class="title-box">假牙空泡</div>
        <div class="liebiao">
           
            <div class="top">
              <div v-for="item in ywxxtop" :class="{ space: item.shuzhi == 11 }">
                <div class="yachi">
                  <img :src="item.imageUrl" :style="item.kuang" v-if="!item.checked" @click="clickYachi(item, true)" />
                  <img :src="item.imageUrlactive" :style="item.kuang" @click.stop="clickYachi(item, false)" v-else />
                  {{ item.shuzhi }}
                </div>
              </div>
            </div>
            
            <div class="bottom">
              <div v-for="item in ywxxbottom" :class="{ space: item.shuzhi == 41 }">
                <div class="yachi">
                  {{ item.shuzhi }}
                  <img :src="item.imageUrl" :style="item.kuang" v-if="!item.checked" @click="clickYachi(item, true)" />
                  <img :src="item.imageUrlactive" :style="item.kuang" @click.stop="clickYachi(item, false)" v-else />
                </div>
              </div>
            </div>
            <span class="left-txt">左</span>
            <div class="zhong-x"></div>
            <div class="zhong-y"></div>
            <span class="right-txt">右</span>
          </div>
      </div>
      <div class="item">
        <div class="title-box">加工备注</div>
        <div>
          <textarea  style="resize:none;" ></textarea>
        </div>
       
      </div>
      <newhospital @pushyiyshuj="pushyiyshuj" ref="tanchuangyiy"></newhospital>
      <newsite @pushdizhishuju="pushdizhishuju" ref="tanchuangdizhi"></newsite>
    </div>
    <div class="btn-footer">
     <div class="btn-zancun">暂存</div>
     <div class="btns"> <div class="btn-baocun">提交</div> <div class="btn-quxiao">取消</div></div>
    </div>
  </div>
</template>
<script>
import newsite from "@/views/newtangc/newsite";
import newhospital from "@/views/newtangc/newhospital";

export default {
  components: {
    newsite,
    newhospital,
  },
  props: {
    binglft: {
      type: Boolean,
    },
  },
  data() {
    return {
      erpcaseno:'',
      strName: [],
      str: [],
      ywxxtop: [
        {
          kuang: "width:31px",
          gao: "height:46px",
          imageUrl: require("@/assets/yatao/tooth18.png"),
          imageUrlactive: require("@/assets/yatao/18-.png"),
          disUrl: require("@/assets/yatao/18-d.png"),
          shuzhi: 18,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 18, end: 17, num: 0 }, //牙间隙
        },
        {
          kuang: "width:34px",
          gao: "height:51px",
          imageUrl: require("@/assets/yatao/tooth17.png"),
          imageUrlactive: require("@/assets/yatao/17-.png"),
          disUrl: require("@/assets/yatao/17-d.png"),
          shuzhi: 17,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 17, end: 16, num: 0 }, //牙间隙
        },
        {
          kuang: "width:42px",
          gao: "height:53px",
          imageUrl: require("@/assets/yatao/tooth16.png"),
          imageUrlactive: require("@/assets/yatao/16-.png"),
          disUrl: require("@/assets/yatao/16-d.png"),
          shuzhi: 16,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 16, end: 15, num: 0 }, //牙间隙
        },
        {
          kuang: "width:22px",
          gao: "height:56px",
          imageUrl: require("@/assets/yatao/tooth15.png"),
          imageUrlactive: require("@/assets/yatao/15-.png"),
          disUrl: require("@/assets/yatao/15-d.png"),
          shuzhi: 15,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          ruya: { num: 55 },
          yjx: { start: 15, end: 14, num: 0 }, //牙间隙
        },
        {
          kuang: "width:23px",
          gao: "height:61px",
          imageUrl: require("@/assets/yatao/tooth14.png"),
          imageUrlactive: require("@/assets/yatao/14-.png"),
          disUrl: require("@/assets/yatao/14-d.png"),
          shuzhi: 14,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 14, end: 13, num: 0 }, //牙间隙
          ruya: { num: 54 },
        },
        {
          kuang: "width:30px",
          gao: "height:81px",
          imageUrl: require("@/assets/yatao/tooth13.png"),
          imageUrlactive: require("@/assets/yatao/13-.png"),
          disUrl: require("@/assets/yatao/13-d.png"),
          shuzhi: 13,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 13, end: 12, num: 0 }, //牙间隙
          ruya: { num: 53 },
        },
        {
          kuang: "width:22px",
          gao: "height:66px",
          imageUrl: require("@/assets/yatao/tooth12.png"),
          imageUrlactive: require("@/assets/yatao/12-.png"),
          disUrl: require("@/assets/yatao/12-d.png"),
          shuzhi: 12,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 12, end: 11, num: 0 }, //牙间隙
          ruya: { num: 52 },
        },
        {
          kuang: "width:30px",
          gao: "height:80px",
          imageUrl: require("@/assets/yatao/tooth11.png"),
          imageUrlactive: require("@/assets/yatao/11-.png"),
          disUrl: require("@/assets/yatao/11-d.png"),
          shuzhi: 11,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 11, end: 21, num: 0 }, //牙间隙

          ruya: { num: 51 },
        },
        {
          kuang: "width:31px",
          gao: "height:78px",
          imageUrl: require("@/assets/yatao/tooth21.png"),
          imageUrlactive: require("@/assets/yatao/21-.png"),
          disUrl: require("@/assets/yatao/21-d.png"),
          shuzhi: 21,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 21, end: 22, num: 0 }, //牙间隙
          ruya: { num: 61 },
        },
        {
          kuang: "width:22px",
          gao: "height:65px",
          imageUrl: require("@/assets/yatao/tooth22.png"),
          imageUrlactive: require("@/assets/yatao/22-.png"),
          disUrl: require("@/assets/yatao/22-d.png"),
          shuzhi: 22,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 22, end: 23, num: 0 }, //牙间隙
          ruya: { num: 62 },
        },
        {
          kuang: "width:31px",
          gao: "height:81px",
          imageUrl: require("@/assets/yatao/tooth23.png"),
          imageUrlactive: require("@/assets/yatao/23-.png"),
          disUrl: require("@/assets/yatao/23-d.png"),
          shuzhi: 23,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 23, end: 24, num: 0 }, //牙间隙
          ruya: { num: 63 },
        },
        {
          kuang: "width:24px",
          gao: "height:63px",
          imageUrl: require("@/assets/yatao/tooth24.png"),
          imageUrlactive: require("@/assets/yatao/24-.png"),
          disUrl: require("@/assets/yatao/24-d.png"),
          shuzhi: 24,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 24, end: 25, num: 0 }, //牙间隙

          ruya: { num: 64 },
        },
        {
          kuang: "width:23px",
          gao: "height:55px",
          imageUrl: require("@/assets/yatao/tooth25.png"),
          imageUrlactive: require("@/assets/yatao/25-.png"),
          disUrl: require("@/assets/yatao/25-d.png"),
          shuzhi: 25,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 25, end: 26, num: 0 }, //牙间隙
          ruya: { num: 65 },
        },
        {
          kuang: "width:42px",
          gao: "height:53px",
          imageUrl: require("@/assets/yatao/tooth26.png"),
          imageUrlactive: require("@/assets/yatao/26-.png"),
          disUrl: require("@/assets/yatao/26-d.png"),
          shuzhi: 26,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 26, end: 27, num: 0 }, //牙间隙
        },
        {
          kuang: "width:36px",
          gao: "height:50px",
          imageUrl: require("@/assets/yatao/tooth27.png"),
          imageUrlactive: require("@/assets/yatao/27-.png"),
          disUrl: require("@/assets/yatao/27-d.png"),
          shuzhi: 27,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 27, end: 28, num: 0 }, //牙间隙
        },
        {
          kuang: "width:31px",
          gao: "height:46px",
          imageUrl: require("@/assets/yatao/tooth28.png"),
          imageUrlactive: require("@/assets/yatao/28-.png"),
          disUrl: require("@/assets/yatao/28-d.png"),
          shuzhi: 28,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
        },
      ],
      ywxxbottom: [
        {
          kuang: "width:37px",
          gao: "height:57px",
          imageUrl: require("@/assets/yatao/tooth48.png"),
          imageUrlactive: require("@/assets/yatao/48-.png"),
          disUrl: require("@/assets/yatao/48-d.png"),
          shuzhi: 48,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 48, end: 47, num: 0 }, //牙间隙
        },
        {
          kuang: "width:36px",
          gao: "height:60px",
          imageUrl: require("@/assets/yatao/tooth47.png"),
          imageUrlactive: require("@/assets/yatao/47-.png"),
          disUrl: require("@/assets/yatao/47-d.png"),
          shuzhi: 47,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 47, end: 46, num: 0 }, //牙间隙
        },
        {
          kuang: "width:42px",
          gao: "height:58px",
          imageUrl: require("@/assets/yatao/tooth46.png"),
          imageUrlactive: require("@/assets/yatao/46-.png"),
          disUrl: require("@/assets/yatao/46-d.png"),
          shuzhi: 46,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 46, end: 45, num: 0 }, //牙间隙
        },
        {
          kuang: "width:27px",
          gao: "height:62px",
          imageUrl: require("@/assets/yatao/tooth45.png"),
          imageUrlactive: require("@/assets/yatao/45-.png"),
          disUrl: require("@/assets/yatao/45-d.png"),
          shuzhi: 45,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 45, end: 44, num: 0 }, //牙间隙
          ruya: { num: 85 },
        },
        {
          kuang: "width:26px",
          gao: "height:62px",
          imageUrl: require("@/assets/yatao/tooth44.png"),
          imageUrlactive: require("@/assets/yatao/44-.png"),
          disUrl: require("@/assets/yatao/44-d.png"),
          shuzhi: 44,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 44, end: 43, num: 0 }, //牙间隙
          ruya: { num: 84 },
        },
        {
          kuang: "width:29px",
          gao: "height:78px",
          imageUrl: require("@/assets/yatao/tooth43.png"),
          imageUrlactive: require("@/assets/yatao/43-.png"),
          disUrl: require("@/assets/yatao/43-d.png"),
          shuzhi: 43,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 43, end: 42, num: 0 }, //牙间隙
          ruya: { num: 83 },
        },
        {
          kuang: "width:22px",
          gao: "height:62px",
          imageUrl: require("@/assets/yatao/tooth42.png"),
          imageUrlactive: require("@/assets/yatao/42-.png"),
          disUrl: require("@/assets/yatao/42-d.png"),
          shuzhi: 42,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 42, end: 41, num: 0 }, //牙间隙
          ruya: { num: 82 },
        },
        {
          kuang: "width:22px",
          gao: "height:59px",
          imageUrl: require("@/assets/yatao/tooth41.png"),
          imageUrlactive: require("@/assets/yatao/41-.png"),
          disUrl: require("@/assets/yatao/41-d.png"),
          shuzhi: 41,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 41, end: 31, num: 0 }, //牙间隙
          ruya: { num: 81 },
        },
        {
          kuang: "width:22px",
          gao: "height:59px",
          imageUrl: require("@/assets/yatao/tooth31.png"),
          imageUrlactive: require("@/assets/yatao/31-.png"),
          disUrl: require("@/assets/yatao/31-d.png"),
          shuzhi: 31,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 31, end: 32, num: 0 }, //牙间隙
          ruya: { num: 71 },
        },
        {
          kuang: "width:22px",
          gao: "height:61px",
          imageUrl: require("@/assets/yatao/tooth32.png"),
          imageUrlactive: require("@/assets/yatao/32-.png"),
          disUrl: require("@/assets/yatao/32-d.png"),
          shuzhi: 32,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 32, end: 33, num: 0 }, //牙间隙
          ruya: { num: 72 },
        },
        {
          kuang: "width:28px",
          gao: "height:77px",
          imageUrl: require("@/assets/yatao/tooth33.png"),
          imageUrlactive: require("@/assets/yatao/33-.png"),
          disUrl: require("@/assets/yatao/33-d.png"),
          shuzhi: 33,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 33, end: 34, num: 0 }, //牙间隙
          ruya: { num: 73 },
        },
        {
          kuang: "width:25px",
          gao: "height:62px",
          imageUrl: require("@/assets/yatao/tooth34.png"),
          imageUrlactive: require("@/assets/yatao/34-.png"),
          disUrl: require("@/assets/yatao/34-d.png"),
          shuzhi: 34,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 34, end: 35, num: 0 }, //牙间隙
          ruya: { num: 74 },
        },
        {
          kuang: "width:28px",
          gao: "height:62px",
          imageUrl: require("@/assets/yatao/tooth35.png"),
          imageUrlactive: require("@/assets/yatao/35-.png"),
          disUrl: require("@/assets/yatao/35-d.png"),
          shuzhi: 35,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 35, end: 36, num: 0 }, //牙间隙
          ruya: { num: 75 },
        },
        {
          kuang: "width:42px",
          gao: "height:59px",
          imageUrl: require("@/assets/yatao/tooth36.png"),
          imageUrlactive: require("@/assets/yatao/36-.png"),
        disUrl: require("@/assets/yatao/36-d.png"),
          shuzhi: 36,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 36, end: 37, num: 0 }, //牙间隙
        },
        {
          kuang: "width:37px",
          gao: "height:59px",
          imageUrl: require("@/assets/yatao/tooth37.png"),
          imageUrlactive: require("@/assets/yatao/37-.png"),
          disUrl: require("@/assets/yatao/37-d.png"),
          shuzhi: 37,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
          yjx: { start: 37, end: 38, num: 0 }, //牙间隙
        },
        {
          kuang: "width:37px",
          gao: "height:56px",
          imageUrl: require("@/assets/yatao/tooth38.png"),
          imageUrlactive: require("@/assets/yatao/38-.png"),
          disUrl: require("@/assets/yatao/38-d.png"),
          shuzhi: 38,
          alter: false,
          zujkozhi: false,
          titp1: "",
          titp2: "",
          titp3: "",
        },
      ],
      selectActive: false,
      strName: [],
      dizAddress: "",
      isShow: false,
      str: [],
      yjmx: [],
      dialogVisible5: false,
      takeNo: "",
      isEdit: false,
      empPhone: "",
      //寄件信息
      jjxl: {
        jjdz: {},
        sjdz: {},
        yyqj: "",
      },
      nowH: "",
      isOpen: true,
      //寄件信息
      caseNo: "",
      caseNoId: "", //寄件单号
      ht: { id: 3, checked: false },
      mt: { id: 2, checked: false },
      jt: { id: 1, checked: false },
      qjsj: {}, //取件时间
      diz: "",
      hoursList: [],
      dizshuzu: [],
      address: {
        province: "天津市",
        city: "天津市",
        area: "东丽区空港经济区",
        address: "中环西路56号中科天保智谷9号楼正丽科技 ",
        deliveryName: "正丽科技 ",
        phone: "15339368485",
      },
      origin: "", //
      selectedArrData: [], // 把选择到的当前行的id存到数组中
      imgEidt: require("@/assets/address_edit.png"),
      imgCopy: require("@/assets/copy.png"),
      imgUrl: require("@/assets/check-circle.png"),
      search: "",
      dialogVisible2: false,
      dialogVisible: false,
      dialogVisible3: false,
      dialogVisible4: false, //邮寄地址选择
      isQjsj: false,
      radio: 1, //上传类型
      upType: 1, //上传方式
      mailType: 0, //邮寄方式
      tableData: [],
      tableData2: [
        {
          id: 1,
          upTime: "2022.08.12",
          name: "李尔",
          urlId: "magicalign.com",
          checked: false,
        },
        {
          id: 2,
          upTime: "2022.08.12",
          name: "李尔2",
          urlId: "magicalign.com",
          checked: false,
        },
      ],
      uplist: [],
      newImg: require("@/assets/2.2病例详情/newfile.png"),
      fileImg: require("@/assets/2.2病例详情/file.png"),
      // 是否是新建,true不是,false是

      isNew: true,
      fileList: [],
      // 是否上传成功
      flag: true,
      OK: require("@/assets/3新建病例/上传文件.png"), //已上传
      NO: require("@/assets/3新建病例/PC-新建病例上传文件.png"), //未上传
      type: "",
      scwjadd: "Y",
      baseURL: "", // 上传文件路径默认地址
      newArr: {},
      newImg: require("@/assets/2.2病例详情/newfile.png"),
      fileImg: require("@/assets/2.2病例详情/file.png"),
      doctorName: localStorage.getItem("doctorName"),
      input1: "",
      input2: "",
      input3: "",
      form: {
        name: "",
        yiy: "",
        diz: "",
        leix: "",
        resource: 1,
      },
      //地址查询条件
      searchAddress: "",
      // 医院
      yiyshuzu: [],
      //病例
      binglisuzu: [
        { name: "成人", id: 1 },
        { name: "儿童", id: 2 },
      ],
      // 地址
      dizshuzu: [],
      // 弹出框
    };
  },
  created() {
    this.yiyjieko();
    this.dizhijieko();
    //    this.binglileixing();
  },
  mounted() {
    this.$store.commit("changeCopy", true);

    // this.init();
  },
  methods: {
      // 删除文件
      remove(val) {
      this.$confirm("你是否要删除文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.fileList = this.fileList.filter((item) => {
            if (item.uid != val.uid) {
              return true;
            }
            if (val.id) {
              this.removeFile(val.id);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    },
        // 某一行被点击行触发事件，默认形参代表一整行数据
        rowClickEv(row) {
      console.log(row); //点击的那行数据默认是对象{__ob__: Observer}，将其转数组
      row.checked = !row.checked;
      if (row.checked) {
        this.selectedArrData.push(row);
      } else {
        this.selectedArrData = this.selectedArrData.filter((item) => {
          if (item.id != row.id) {
            return true;
          }
        });
      }
    },

    isRed({ row }) {
      const checkIdList = this.selectedArrData.map((item) => item.id);
      if (checkIdList.includes(row.id)) {
        return {
          backgroundColor: "#1275D2",
          color: "#FFFFFF",
        };
      }
    },
    
    timeFormat() {
      let time = new Date();
      let y = time.getFullYear();
      let m = this.addZero(time.getMonth() + 1);
      let d = this.addZero(time.getDate());
      let h = time.getHours();
      this.jt.txt = "今天";
      this.jt.time = `${y}.${m}.${d}`;
      this.jt.formatTime = `${y}-${m}-${d}`;
      this.jt.h = h;
      time = new Date(new Date().getTime() + 3600000 * 24);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.mt.txt = "明天";
      this.mt.time = ` ${y}.${m}.${d}`;
      this.mt.formatTime = `${y}-${m}-${d}`;
      time = new Date(new Date().getTime() + 3600000 * 24 * 2);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.ht.txt = "后天";

      this.ht.time = ` ${y}.${m}.${d}`;
      this.ht.formatTime = `${y}-${m}-${d}`;
    },
    addZero(val) {
      if (val < 10) {
        return "0" + val;
      } else {
        return val;
      }
    },
    copyAddress() {
      let copyInput = document.createElement("input"); //创建input元素
      document.body.appendChild(copyInput); //向页面底部追加输入框
      copyInput.setAttribute(
        "value",
        this.jjxl.sjdz.province +
          this.jjxl.sjdz.city +
          this.jjxl.sjdz.area +
          this.jjxl.sjdz.address +
          this.jjxl.sjdz.deliveryName +
          this.jjxl.sjdz.contactNumber
      ); //添加属性，将url赋值给input元素的value属性
      copyInput.select(); //选择input元素
      document.execCommand("Copy"); //执行复制命令
      this.$message.success("地址复制成功"); //弹出提示信息，不同组件可能存在写法不同
      //复制之后再删除元素，否则无法成功赋值
      copyInput.remove(); //删除动态创建的节点
    },
    eidtAddres() {
      if(this.diz){

      this.$refs.tanchuangdizhi.dialogFormVisibledizhi = true;
    }else{
      this.$message({ message: "请选择地址", type: "error" });

    }
    },
    handleCurrentChange(val) {
      this.tableData2 = this.tableData2.filter((item) => {
        if (val.id == item.id) {
          item.checked = !item.checked;
        }
        return true;
      });
      console.log(this.tableData2);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          this.isEdit = false;
          done();
        })
        .catch(() => {});
    },
    yjmxChange(val) {
      if (this.yjmx.indexOf(val) != -1) {
        this.yjmx.splice(this.yjmx.indexOf(val), 1);
      } else {
        this.yjmx.push(val);
      }
      if (this.yjmx.length == 0) {
        this.mailType = 0;
      }
    },
    tihuan() {
      console.log(this.str);
      for (let i = 0; i < this.str.length; i++) {
        if (this.str[i].name) {
          for (var j = 0; j < this.fileList.length; j++) {
            if (this.fileList[j].name == this.str[i].name) {
              this.fileList.splice(j, 1);
              break;
            }
          }
          for (var j = 0; j < this.fileList.length; j++) {
            if (this.fileList[j].name == this.str[i].name) {
              this.fileList[j].casedataid = this.str[i].casedataid;
              this.fileList[j].update = true;
              break;
            }
          }
        }
      }
      this.isShow = false;
    },
    baoliu() {
      let obj = {};

      for (let j = 0; j < this.fileList.length; j++) {
        let gs = this.fileList[j].name.substr(
          this.fileList[j].name.lastIndexOf("."),
          this.fileList[j].name.length
        );
        let name = this.fileList[j].name.substr(
          0,
          this.fileList[j].name.lastIndexOf(".")
        );

        for (let i = 0; i < this.fileList.length; i++) {
          let fileGs = this.fileList[i].name.substr(
            this.fileList[i].name.lastIndexOf("."),
            this.fileList[i].name.length
          );
          let fileName = "";
          if (gs == fileGs) {
            if (this.fileList[i].name.indexOf(/([0-9])/) != -1) {
              fileName = this.fileList[i].name.substr(
                0,
                this.fileList[i].name.lastIndexOf(/([0-9])/)
              );
            } else {
              fileName = this.fileList[i].name.substr(
                0,
                this.fileList[i].name.lastIndexOf(".")
              );
            }

            if (fileName == name) {
              console.log(obj, obj[name], name);
              if (obj[name] != undefined) {
                this.fileList[i].name = fileName + "(" + obj[name]++ + ")" + gs;
              } else {
                obj[name] = 1;
              }
            }
          }
        }
      }

      this.isShow = false;
    },
    handleSuccess(file, fileList) {
      if (!this.ifType(file, fileList)) {
        fileList.pop();

        return;
      }

      this.file = file;

      this.fileList = fileList;
      this.$emit("shangchuan", true);
      //需要上传的文件

      this.scwjadd = "N"; //是否新建
      this.flag = false;
    },
    ifType(file, fileList) {
      const fileExt = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();

      const isLt2M = file.raw.size / 1024 / 1024 < 100;

      if (!isLt2M) {
        this.$message.error("上传文件最大100MB !");
        return false;
      }

      this.str = [];
      this.strName = [];
      for (let i = 0; i < fileList.length; i++) {
        for (let j = 0; j < fileList.length; j++) {
          if (i != j && fileList[i].name == fileList[j].name) {
            if (!this.str.includes(fileList[i].name)) {
              this.str.push(fileList[i]);
              this.str.push(fileList[i].name);
              this.strName.push(fileList[i].name);
            }
          }
        }
      }
      if (this.str.length > 0) {
        this.isShow = true;
      }
      return true;
    },
    upChange() {
      if (this.radio == 2) {
        this.getSFById();
      }
    },
    clickYachi(val, flag) {
      this.ywList = [];
      this.ywxxtop = this.ywxxtop.filter((item) => {
        if (item.shuzhi == val.shuzhi) {
          item.checked = flag;
        
        }

        this.ywList.push({ num: item.checked ? 1 : 0, shuzhi: item.shuzhi });

        return true;
      });
      this.ywxxbottom = this.ywxxbottom.filter((item) => {
        if (item.shuzhi == val.shuzhi) {
          item.checked = flag;
        
        }
        this.ywList.push({ num: item.checked ? 1 : 0, shuzhi: item.shuzhi });
        return true;
      });

      this.ywList.sort(function (a, b) {
        return a.shuzhi < b.shuzhi ? -1 : 1;
      });
    },
    getSFById(isflg) {
      this.$set(this.jjxl.jjdz, "address", "");
      this.$set(this.jjxl.jjdz, "contactNumber", "");
      this.$set(this.jjxl.jjdz, "deliveryName", "");
      this.$set(this.jjxl, "yyqj", "");

      this.yjmx = [];
      this.edit = 1;
      this.cancel = 1;
      this.state = "";

      let data = { caseIds: this.$store.state.caseId, mailingMethod: this.mailType }; //病例id

      this.$http({
        url: "/webCase/opGetSFById",
        method: "post",
        dataType: "json",
        data: data,
        contentType: "application/x-www-form-urlencoded",
      })
        .then(({ data }) => {
          this.isOpen = true;
          this.LoadingClose();
          if (data.code == 200) {
            if (isflg) {
              this.dialogVisible3 = false;
              this.$message({
                message: "下单成功",
                type: "success",
                duration: 1500,
              });
            }

            let res = JSON.parse(data.data);
            console.log(res);
            if (res != null) {
              this.state = res.state;
              this.edit = res.edit;
              this.cancel = res.cancel;

              this.caseNoId = res.orderNo;
              let sj = "";
              /*  * 寄件地址：
                       中国上海上海闵行万金中心10F
  
                       海波13641636968
  
                       收件地址：
                       中环西路86号中科天保智谷9号楼
  
                       正丽科技质检部15890128745
  
                           * 预约取件：
                       今天(2022.12.21) 18: 00~19: 00 */

              if (res.mailingModel == 1) {
                this.yjmx = [1];
              } else if (res.mailingModel == 3) {
                this.yjmx = [1, 2];
              } else if (res.mailingModel == 2) {
                this.yjmx = [2];
              }
              console.log(this.yjmx);
              if (res.mailingMethod) {
                this.mailType = Number(res.mailingMethod);
              }
              if (res.orderState != "1") {
                let time = new Date();

                time = new Date(res.bookingTime);
                let time2 = new Date(time.getTime() + 1000 * 60 * 60);
                let jt = new Date();
                let Y = jt.getFullYear();
                let M = jt.getMonth() + 1;
                let D = jt.getDate();

                let mt = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    24 * 3600 * 1000
                );
                let ht = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    48 * 3600 * 1000
                );
                let rq = "";
                if (
                  time.getDate() == new Date().getDate() &&
                  time.getMonth() == new Date().getMonth()
                ) {
                  rq = "今天";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 1000 * 60 * 60);
                  } else {
                    time2 = new Date(time.getTime() + 1000 * 60 * 30);
                  }
                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                    time.getMinutes() +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                    time2.getMinutes() +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;
                } else if (time.getTime() < ht.getTime()) {
                  rq = "明天";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 25 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 24 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }
                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                    time.getMinutes() +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                    time2.getMinutes() +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;
                } else {
                  rq = "后天";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 49 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 48 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }

                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                    time.getMinutes() +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                    time2.getMinutes() +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;
                }

                // }
                // this.$set( this.jjxl,'yyqj',  '')
                this.empPhone = res.empPhone;
                this.takeNo = res.takeNo;
                   //   console.log(this.dizshuzu);
                this.dizshuzu.filter((item) => {
                  console.log(item.id, res.sendAddressIdWeb);
                  if (
                    item.id == res.sendAddressIdOrtho ||
                    item.id == res.sendAddressIdWeb
                  ) {
                    this.$set(
                      this.jjxl.jjdz,
                      "address",
                      item.country + item.province + item.city + item.area + item.address
                    );

                    this.$set(this.jjxl.jjdz, "contactNumber", item.contactNumber);
                    this.$set(this.jjxl.jjdz, "deliveryName", item.deliveryName);
                    this.$set(this.jjxl, "yyqj", sj);
                  }
                });
              }
            }
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    stringStr(dizAddress) {
      let str = "";
      if (dizAddress && dizAddress.length > 53) {
        str = dizAddress.substr(0, 53) + "...";
      } else {
        str = dizAddress;
      }
      return str;
    },
   
    // 收货收获地址列表
    dizhijieko() {
      this.$http({
        url: "/deliveryAddress/getAddressList",
        method: "get",
        params: {},
      })
        .then(({ data }) => {
          if (data.code == 200) {
            let dizid = "";
            //      console.log(this.form.diz + "源地址");
             console.log( JSON.parse(data.data))
            this.dizshuzu = JSON.parse(data.data).map((x) => {
             

              return {
                name:
                  x.country +
                  x.province +
                  x.city +
                  x.area +
                  x.address +
                  " - " +
                  x.deliveryName,
                id: x.id,
               
              };
            });
            //   console.log(this.dizshuzu);

            if (this.binglft) {
              this.form.diz = dizid;

              console.log(this.form.diz + "地址id");
            }
            ////   console.log(this.dizshuzu);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 医院地址列表
    yiyjieko() {
      this.$http({
        url: "/hospital/hospitalList",
        method: "get",
        params: {},
      })
        .then(({ data }) => {
          if (data.code == 200) {
            let yiyid = "";
            console.log(JSON.parse(data.data));
            this.yiyshuzu = JSON.parse(data.data).map((x) => {
              
            
              x.id = x.id;
              x.name=x.country+x.province+x.city+x.area+x.address+x.hospitalName
              // console.log(x);
               return x
            });
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 医院 显示弹窗
    xzyybut() {
      this.$refs.tanchuangyiy.dialogFormVisibleyiy = true;
    },
    // 地址 显示弹窗
    xzdzbut() {
      this.$refs.tanchuangdizhi.dialogFormVisibledizhi = true;
    },
  
    // 新增医院 方法
    pushyiyshuj() {
      this.yiyjieko();
      // this.form.yiy =
      // this.$refs.tanchuangyiy.country +
      // this.$refs.tanchuangyiy.province +
      // this.$refs.tanchuangyiy.city +
      // this.$refs.tanchuangyiy.area +
      // this.$refs.tanchuangyiy.form.dizhi;
      // this.yiyshuzu.push(this.form.yiy);
    },
    // 新增地址方法
    pushdizhishuju() {
      this.dizhijieko();
      // this.form.diz =
      // this.$refs.tanchuangdizhi.country +
      // this.$refs.tanchuangdizhi.province +
      // this.$refs.tanchuangdizhi.city +
      // this.$refs.tanchuangdizhi.area +
      // this.$refs.tanchuangdizhi.form.diz;
      // this.dizshuzu.push(this.form.diz)
    },

   
  },
};
</script>
<style scoped lang="less">
.jgxx {
  margin-top: 40px;
  margin-bottom: 40px;
}
.footer {
  position: absolute;
  margin-top: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.space {
  margin-right: 10px;
}

.qjxl {
  .title {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  span,
  .title {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ff0000;
  }

  .jjdz {
    display: flex;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    margin-bottom: 16px;

    .space {
      margin-left: 10px;
    }

    & > div:first-of-type {
      width: 90px;
    }
  }

  .order-btn {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;

    .active {
      color: #1275d2;
      text-decoration: underline;
    }
    & > div {
      width: auto !important;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.qjsj {
  padding-left: 12px;
  width: 300px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #1275d2;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
    //transform: translateY(-50%);
  }

  .qjsj-box {
    left: 0;
    width: 560px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    position: absolute;
    background: #ffffff;
    top: 42px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .qjsj-day {
      padding: 16px 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      div {
        cursor: pointer;
        width: 160px;
        height: 28px;
        font-size: 14px;
        border: 1px solid #1275d2;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .active {
        background: #1275d2;
        color: #ffffff;
      }
    }

    .qjsj-line {
      margin: auto;
      width: 540px;
      height: 1px;
      background: #999999;
    }

    .qjsj-hour {
      margin-right: -18px;
      flex-shrink: 1;
      overflow-y: scroll;
      display: flex;
      padding-left: 16px;
      align-items: center;
      flex-wrap: wrap;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 16px;
        margin-right: 18px;
        width: 90px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #1275d2;
        display: flex;
        justify-items: center;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;

        p {
          margin-top: 0;
        }
      }

      div:nth-of-type(5n) {
        margin-right: 0;
      }

      .active {
        background: #1275d2;
        color: #ffffff;
      }
    }
  }
}

.close::after {
  top: 19%;
  border: 8px solid transparent;
  border-bottom: 8px solid #1275d2;
}

.click-select {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 10px;
  padding-right: 35px;
  background: white;
  z-index: 10;
  width: 300px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #1275d2;
  &.active::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
  }
}
/deep/ .express {
  height: 448px;

  .el-input__inner {
    margin-left: 1px;
    margin-top: 1px;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: transparent;
    color: transparent;
  }

  .edit-box {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }

  .code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
  }

  .detail {
    align-self: flex-start;

    margin-left: 0;
  }

  .text {
    margin-top: 2px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
}

.copy-icon {
  cursor: pointer;
  align-self: end;
}

.address {
  margin-top: 32px;
  display: flex;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
}

/deep/ .tangcform {
  padding-top: 20px;
}

.blue-btn {
  cursor: pointer;
  width: 96px;
  height: 28px;
  background: #1275d2;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
}

.img-box {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 9px;

  img {
    width: 100%;
    display: block;
  }
}

.table {
  /deep/ th {
    border-bottom: #1275d2 2px solid;
  }

  /deep/ td {
    cursor: pointer;
  }

  // 修改鼠标经过表格的颜色
  /deep/ .el-table tbody tr:hover > td {
    // 可以选择隐藏
    background-color: transparent !important;
    opacity: 0.8;
  }
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__header {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}

/deep/ .el-dialog__title {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 32px;
  padding: 0;
}

/deep/.el-dialog__headerbtn {
  top: 8px;
}

/deep/.el-dialog__header {
  height: 32px;
  background: #1275d2;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.dialog-div {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.canel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #0075f6;
}

.submit {
  cursor: pointer;
  margin-right: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 24px;
  background: #1275d2;
  border-radius: 4px;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
}

.dialog-box {
  padding-left: 24px;

  p {
    margin-top: 8px;
  }

  .qjsj {
    /deep/.el-input__inner {
      margin-top: 8px;
      width: 400px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #1275d2;
    }
  }

  /deep/ .el-icon-arrow-up:before {
    color: #1275d2;
  }
}

.div-text {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
  margin-top: 32px;
}

.search-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-left: 24px;
  padding-right: 15px;
  padding-top: 32px;
  padding-bottom: 36px;

  /deep/ .el-input {
    width: auto;
  }

  /deep/.el-input__inner {
    margin-top: 0;
    width: 240px;
    height: 32px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
  }

  /deep/ .el-input__icon {
    line-height: 32px;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    background: #1275d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
  }
}

.del {
  cursor: pointer;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ff0000;
  line-height: 20px;
  text-decoration: underline;
}

.xuanze {
  cursor: pointer;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #1275d2;
  line-height: 20px;
  text-decoration: underline;
}

.table-box {
  width: 772px;
  margin-left: 76px;
  margin-top: 40px;

  /deep/ .el-table {
    background: transparent;

    .el-table__header {
      background: transparent;
    }

    th {
      border-bottom: 2px #1a95eb solid;
    }

    th,
    tr {
      background-color: transparent;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 20px;
    }
  }
}

.blue-bg {
  background-color: #1275d2;
  color: #ffffff !important;
}

.detail-address {
  margin-left: 20px;
}

.detail {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;

  display: flex;
  align-items: center;

  span {
    color: #ff0000;
  }
}

.radio-div {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;

  div {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    cursor: pointer;
    margin-right: 32px;
    padding: 0 20px;
    height: 28px;
    border-radius: 16px;
    border: 1px solid #1275d2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.radio-box {
}

/deep/.upload-demo {
  c .el-upload-list {
    position: absolute;
    left: 50%;
    top: 225px;
    transform: translateX(-50%);
    width: 200px;

    .el-upload-list__item-status-label,
    .el-icon-close {
      top: -238px;
      right: -202px;
      opacity: 0;
      display: block;
    }

    .el-upload-list__item-status-label {
      .el-icon-upload-success {
        width: 604px;
        height: 300px;
      }
    }

    .el-icon-close {
      width: 604px;
      height: 300px;
    }
  }
}

.scwj {
  max-height: 200px;
}

.wenjscbox {
  .upFile {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .fileName {
      max-width: 160px;
      padding-right: 10px;
      height: 20px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn {
      cursor: pointer;
      color: red;
    }
  }
}

.wenjscbox {
  width: 600px;

  img {
    width: 26px;
    height: 26px;
    margin-right: 8px;
  }

  .btn {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    line-height: 20px;
  }
}

.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0e, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;

  & > div {
    background: #f9f9f9;
    width: 500px;
    height: 200px;
    left: 50%;
    top: 50%;

    div {
      margin-top: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        display: inline-block;
        max-width: 150px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    transform: translate(-50%, -50%);

    .top {
      margin-top: 0;
      height: 20px;
      background: #333333;
     
    }

    .footer {
      bottom: 20px;
      display: flex;
      left: 50%;
      transform: translateX(-50%);

      & > div {
        margin-right: 50px;
        background: #1175d2;
        border-radius: 5px;
        padding: 5px;
        color: #f9f9f9;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}
.disabled {
  color: #666666;
}
.my-select {
  .el-select-dropdown__list {
    padding-bottom: 20px;
  }
}
/deep/ .el-radio__inner {
  width: 20px;
  height: 20px;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #fff !important;
  width: 20px;
  height: 20px;
  &::after {
    width: 10px;
    height: 10px;
    background: #1175d2;
    border-radius: 50%;
  }
}
.jkd {
  /deep/ .el-dialog__close {
    color: #ffffff;
  }
  /deep/.el-dialog__body {
    overflow: inherit;
  }
}
.hegu {
  .title {
    display: flex;
    align-items: center;
  }

  .title::before {
    top: 2px !important;
  }
}
.item{
    display: flex;
    margin-top: 31px;
      textarea{
          padding: 10px;
         box-sizing: border-box;
        width: 760px;
height: 88px;
background: #FFFFFF;
border-radius: 4px;
border: 1px solid #1A95EB;
   outline: none;
      }
}
.title-box {
  width: 108px;
  position: relative;
  margin-left: 30px;
  font-size: 16px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: 700;
  color: #333333;
}
.item-box {
  display: flex;

  & > .title {
    width: 108px;
    position: relative;
    margin-left: 30px;
    font-size: 16px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: 700;
    color: #333333;
    &::before {
      content: "*";
      font-weight: 900;
      font-size: 16px;
      color: #ff0000;
      position: absolute;
      right: 32px;
      top: -5px;
      letter-spacing: 0px;
    }
  }
  input {
    width: 120px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #1a95eb;
    outline: none;
    text-align: center;
  }
}
.liebiao {
    position: relative;
    width: 650px;
    height: 139px;

    .bottom {
      .icon-img.space {
        margin-left: 8px;
        margin-right: 15px;
      }
    }

    .bottom,
    .top {
      z-index: 10;
      width: 597px;
      position: absolute;
      display: flex;
      left: 50%;
      transform: translateX(-50%);

      .space {
        margin-left: 0;
        margin-right: 15px;
      }

      .yachi {
        font-size: 12px;
font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
font-weight: normal;
color: #999999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 65px;
      }

      &>div {
        display: flex;
        margin-right:7.5px;

        img {
          display: block;
          align-self: flex-end;
        }

        .icon-img {
          z-index: 10;
          align-self: flex-end;
          position: relative;
          margin-bottom: 25px;
          width: 18px;
          height: 18px;

          img {
            cursor: pointer;
          }

          .yjx-msg {
            z-index: 10;
            position: absolute;
            top: 30px;
            left: -15px;
            width: 324px;
            height: 46px;
            background: #ffffff;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            border: 1px solid #d1d1d1;
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #666666;
            display: flex;
            align-items: center;
            padding-left: 19px;
            box-sizing: border-box;

            &::after {
              content: "";
              position: absolute;
              left: 15px;
              top: -25px;
              border: 9px solid transparent;
              border-bottom: 16px solid #d4d4d4;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
            }

            input {
              width: 64px;
              height: 32px;
              border-radius: 4px;
              border: 1px solid #d4d4d4;
              outline: none;
              font-size: 14px;
              font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #333333;
              text-align: center;
            }

            .del-btn {
              cursor: pointer;
              color: #ff0000;
              height: 100%;
              margin-left: 26px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .bottom {
      top:75px;
      z-index: 9;

      .yachi {
        justify-content: flex-start;
        
      }

      &>div {
        display: flex;

        img {
          display: block;
          align-self: flex-start;
        }

        .icon-img {
          align-self: flex-start;
          margin-bottom: 0;
          margin-top: 25px;
        }
      }
    }

    .left-txt {
      font-size: 12px;
font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
font-weight: normal;
color: #999999;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .right-txt {
      font-size: 12px;
font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
font-weight: normal;
color: #999999;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .zhong-x {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 597px;
      height: 1px;
      background-color: #7bb2e1;
    }

    .zhong-y {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 139px;
      background-color: #7bb2e1;
    }
  }
</style>
<style lang="less" scoped>
@media screen and (max-width: 1660px) {
  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .butonezu .el-button {
    transform: scale(0.8);
    margin: 0px;
  }

  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu .el-button {
    transform: scale(0.8);
    margin: 0px;
    width: 140px;
    float: left;
    display: block;
  }

  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu {
    width: 140px !important;
    // bottom: -36% !important;
    right: 2% !important;
  }
  #particulars-schedule /deep/ .el-dialog {
    margin-top: 18vh !important;
    transform: scale(0.9);
    height: 475px !important;
  }
  #particulars-schedule .el-dialog .el-dialog__body .koko {
    margin-bottom: 20px !important;
  }

  #particulars-schedule .el-dialog .el-dialog__body .wrap {
    height: 300px !important;
  }
}

@media screen and (max-width: 1360px) {
  #particulars-schedule /deep/ .el-dialog {
    transform: scale(0.8);
    height: 450px !important;
  }
  #particulars-schedule .el-dialog .el-dialog__body .koko {
    margin-bottom: 20px !important;
  }

  #particulars-schedule .el-dialog .el-dialog__body .wrap {
    height: 300px !important;
  }
}
</style>
<style scoped lang="less">
.dialog-box {
  padding-left: 24px;

  p {
    margin-top: 8px;
  }

  .qjsj {
    /deep/.el-input__inner {
      margin-top: 8px;
      width: 400px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #1275d2;
    }
  }

  /deep/ .el-icon-arrow-up:before {
    color: #1275d2;
  }
}

.detail {
  align-self: flex-start;

  margin-left: 0;
}
.click-select {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 10px;
  padding-right: 35px;
  background: white;
  z-index: 10;
  width: 300px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #1275d2;
  &.active::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
  }
}
/deep/ .express {
  height: 448px;

  .el-input__inner {
    margin-left: 1px;
    margin-top: 1px;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: transparent;
    color: transparent;
  }

  .edit-box {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }

  .code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
  }
  .my-select {
    .el-select-dropdown__list {
      padding-bottom: 20px;
    }
  }

  .detail {
    align-self: flex-start;
    margin-top: 32px;
    margin-left: 0;
  }

  .text {
    margin-top: 2px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
}
.code {
  margin-top: 32px;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #0075f6;
}
.div-text {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
  margin-top: 32px;
}
.jkd {
  /deep/ .el-dialog__close {
    color: #ffffff;
  }
  /deep/.el-dialog__body {
    overflow: inherit;
  }
}
.btn-footer{
    display: flex;
    justify-content: space-between;
    margin-top: 39px;
    margin-bottom: 100px;
    .btns{
        display: flex;

    }
    .btn-quxiao{
      cursor: pointer;
      width: 140px;
height: 36px;
border-radius: 4px;
border: 1px solid #1275D2;
font-size: 16px;
font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
font-weight: normal;
color: #1275D2;
display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 29px;
    }
  .btn-baocun,
  .btn-zancun{
    cursor: pointer;

    width: 140px;
height: 36px;
background: #1275D2;
border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
font-weight: normal;
color: #FFFFFF;
  }
}
.btn-box {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    color: #1275d2;
    font-size: 12px;
    margin-right: 25px;
    cursor: pointer;
  }
  img {
    width: 13px;
    margin-right: 7px;
  }
}
.address {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .logistics {
    width: 400px;
    height: 592px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);

    .title {
      height: 32px;
      background: #343b43;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      padding: 0 20px;
      margin-bottom: 16px;
      span:nth-of-type(2)::after {
        content: "X";
        cursor: pointer;
      }
    }
    .content {
      padding-left: 20px;
    }
    .item {
      display: flex;

      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      p:nth-of-type(1) {
        width: 64px;
      }
      p:nth-of-type(2) {
        width: 180px;
      }

      .icon-img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .btn {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      padding-bottom: 8px;

      border-bottom: #1275d2 solid 1px;
      & > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        margin-right: 26px;
        img {
          margin-right: 2px;
        }
      }
    }
    .list {
      height: 415px;
      overflow: auto;
      .last-box {
        &::before {
          content: "";
          position: absolute;
          z-index: 99;
          left: 0;
          width: 10px;
          height: 10px;
          background: #bababa !important;
          border-radius: 50%;
        }
      }
      & > div {
        display: flex;

        & > div:nth-of-type(1) {
          width: 80px;

          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #666666;
        }
        & > div:nth-of-type(2) {
          width: 230px;
          padding-bottom: 20px;
          display: flex;
          font-size: 14px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;

          position: relative;
          overflow: hidden;
          padding-left: 20px;
          .first {
          }

          .line {
            position: absolute;
            left: 5px;

            border-left: 1px dashed #0089ff;

            height: 100%;
          }
          &::before {
            position: absolute;
            left: 0;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: #0089ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.planFlag {
  /deep/.el-button {
    background: #666 !important;
    border: none !important;
    span {
      color: #fff !important;
    }
  }
}
a {
  text-decoration: none;
  color: #fda21c;
  &:hover {
    color: #333;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  //针对ie
  #particulars-schedule .el-dialog .el-dialog__body .wrap .el-timeline-item__wrapper {
    top: -7px;
  }
}
#particulars-schedule {
  overflow: auto;

  .el-timeline-item {
    height: 70px;
    padding-bottom: 34px;

    .el-timeline-item__dot {
      .el-image {
        margin-left: -8px;
        width: 26px;
        height: 26px;
      }
    }

    /deep/.el-timeline-item__wrapper {
      position: relative;
      padding-left: 36px;
      font-size: 16px;
    }

    /deep/ .el-timeline-item__tail {
      border-left-style: dashed;
    }

    .line {
      height: 0;
      width: 94%;
      position: absolute;
      bottom: -34px;
      right: 0;
      border: 0.5px solid #d6d6d6;
    }

    /deep/.buttwozu {
      // height:36px;
      // width:100%;
      position: absolute;
      right: 2%;
      bottom: 0%;
      z-index: 1;

      .el-button {
        width: 140px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        border: 1px solid #f99400;
        box-sizing: border-box;
        background: #f9f9f9;

        span {
          color: #fda21c;
        }

        // span:hover {
        // 	color: #fff;
        // }
      }

      // .el-button:hover {
      //   background-color: red;
      // }

      .el-button:active {
        // background-color:#1175d2;
      }
    }

    .butonezu {
      // height:36px;
      // width:100%;
      position: absolute;
      right: 2%;
      // margin-top:-15%;
      bottom: 0%;
      z-index: 1;

      /deep/.el-button {
        width: 140px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        background: #f99400;
        border: none;

        span {
          color: #fff;
        }
      }
    }

    .huyi2:hover {
      background-color: #fda21c !important;
    }

    // .line:last-child{
    //     border:none;
    // }
  }

  /deep/ .el-dialog {
    width: 570px;
    height: 610px;
    // margin-top: 18vh !important;
    background: #f9f9f9;
    padding-left: 50px;

    .el-dialog__header {
      padding: 25px 25px 0 40px;

      .el-dialog__title {
        font-weight: normal;
        padding: 0;
      }

      .el-dialog__headerbtn {
        top: 15px;
        right: 17px;
      }

      .el-dialog__headerbtn .el-dialog__close {
        width: 14px;
        height: 14px;
        color: #4b4b4b;
      }
    }

    .el-dialog__body {
      padding: 0;
      text-align: center;
      font-size: 16px;

      .trackingNumber {
        text-align: left;
        margin-bottom: 10px;
      }

      .koko {
        overflow: hidden;
        margin-bottom: 48px;

        .p1 {
          margin-right: 60px;
        }
      }

      .wrap {
        height: 420px;
        padding-right: 120px;
        overflow: auto;
        .el-timeline-item {
          height: auto;
        }
        .el-timeline {
          padding-top: 10px;
        }
        .el-timeline-item__wrapper {
          width: 70%;
          padding-left: 168px;
          top: -12px;
        }
        .el-timeline-item__timestamp {
        }
        .el-timeline-item__tail {
          height: 100%;
          border-left-style: solid;
          border-left-color: #999999;
          left: 129px;
          // top:22px;
        }
        .el-timeline-item__node--normal {
          left: 120px;
          width: 20px;
          height: 20px;
        }
        .el-timeline-item__content,
        .el-timeline-item__timestamp {
          text-align: left;
        }
        .el-timeline-item__timestamp.is-bottom {
          margin-top: 5px;
          color: #666 !important;
        }
        .time {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 16px;
        }
        .date {
          color: #333 !important;
        }
        .specificTime {
          color: #666 !important;
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
@media screen and (max-width: 1660px) {
  #particulars-schedule .el-timeline-item .butonezu .el-button {
    transform: scale(0.8) !important;
    margin: 0px !important;
  }

  #particulars-schedule .el-timeline-item .buttwozu .el-button {
    transform: scale(0.8) !important;
    margin: 0px !important;
    width: 140px !important;
    float: left !important;
  }

  #particulars-schedule .el-timeline-item .buttwozu {
    width: 140px !important;
    // bottom: -36% !important;
    right: 2% !important;
  }
}

@media screen and (max-width: 1360px) {
}
</style>
<style lang="less" scoped>
.el-timeline .el-timeline-item {
  position: relative;
}
.reminder {
  position: absolute;
  top: 0;
  left: 165px;
  width: 260px;
  height: 80px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #aaa;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  z-index: 5;
  .top,
  .bottom {
    font-size: 14px;
    color: #333;
    i {
      font-style: normal;
    }
    span:nth-of-type(1) {
      color: #1175d2;
    }
    span:nth-of-type(2) {
      margin-right: 26px;
    }
  }
  .bottom {
    margin-top: 10px;
    span:nth-of-type(1) {
      margin-right: 13px;
    }
    span:nth-of-type(2) {
      margin-right: 34px;
    }
  }
}

.reminderStyle {
  position: absolute;
  top: 3px;
  left: 138px;
  width: 20px;
  height: 20px;
}
</style>
<style lang="less" scoped>
.my-select {
  /deep/ .el-input input {
    width: 635px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #1a95eb;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner {
    border: 1px solid red;
  }
}
</style>
<style scoped lang="less">
.btn {
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #1275d2;
  padding-bottom: 8px;
  img {
    margin-right: 8px;
  }
  & > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    margin-right: 26px;
    img {
      margin-right: 8px;
    }
  }
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #fff !important;
  &::after {
    width: 10px;
    height: 10px;
    background: #1175d2;
    border-radius: 50%;
  }
}
/deep/.el-icon-arrow-up:before {
  position: absolute;
  width: 0;
  height: 0;
  top: 5px;
  right: 0;
  content: "" !important;
  border: transparent solid 10px;
  border-bottom: 10px solid #1175d2;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}
.main-box {
  max-width: 1600px;
  margin: auto;
}
.jbxx-title {
  font-size: 16px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #1275d2;

  padding-left: 82px;
}
.main-title {
  font-size: 16px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #333333;
  padding-top: 45px;
  padding-bottom: 28px;
}
.jbxx {
  padding-top: 44px;
  max-width: 1600px;
  height: 888px;
  background: #f9f9f9;

  font-size: 16px;
}

/deep/.el-form-item {
  font-weight: 400;
  margin-bottom: 25px;
  position: relative;

  .el-form-item__label {
    font-size: 16px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: 700;
    color: #333333;
  }

  .el-input input {
    /* width: 635px;
height: 36px; */
    border: 1px solid #1175d2;
  }
}

.yiy-btn {
  position: absolute;
  top: 0%;
}

/deep/.jbxxform {
  font-weight: 400;
  margin-bottom: 25px;
  position: relative;

  .el-form-item__label {
    position: relative;

    &::before {
      content: "*";
      font-weight: 900;
      font-size: 16px;
      color: #ff0000;
      position: absolute;
      right: 32px;
      top: -5px;
      letter-spacing: 0px;
    }
  }
}

/deep/.el-form-item__label {
  text-align: center;
  font-size: 16px;
  padding-right: 0px;
  text-align: left;
  // margin-right:40px;
}

/deep/.el-form-item__content {
  // margin-left:40px !important;
  height: 40px;
}

/deep/.huangzname .el-form-item__content {
  width: 400px;
}

/deep/.huangzname {
  padding-top: 24px;
  box-sizing: border-box;
}

/deep/.csrq .el-form-item__content .el-input {
  width: auto;
}

/deep/.csrq .ipt1 .el-input__inner {
  font-weight: 400 !important;
  width: 100px;
  margin-right: 10px;
  text-align: center;
}

/deep/.csrq .ipt2 .el-input__inner {
  font-weight: 400 !important;
  width: 80px;
  margin-right: 10px;
  margin-left: 30px;
  text-align: center;
}

/deep/.csrq .ipt3 .el-input__inner {
  font-weight: 400 !important;
  width: 80px;
  margin-right: 10px;
  margin-left: 30px;
  text-align: center;
}

/deep/.csrq span {
  font-weight: 400 !important;
}

/deep/.el-form {
  padding-left: 80px;
}

/deep/.xingbtit .el-form-item__label {
  letter-spacing: 30px;
  padding: 0px;
  text-align: left;
}

// 性别
/deep/.el-radio__inner {
  border: 1px solid #1175d2 !important;
  width: 20px;
  height: 20px;
}

// 新增
/deep/.el-select .el-input .el-select__caret {
  color: #1175d2;
  font-size: 20px;
  font-weight: 1000;
  // el-icon-caret-bottom
}

/deep/.el-icon-arrow-up:before {
  content: "\e6e1";
}

@media screen and (max-width: 1660px) {
  .jbxx-title {
    padding-left: 30px;
  }
  /deep/.el-form {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: 1200px) {
  /deep/.bcq-input input,
  /deep/.my-select input,
  /deep/.shdz input,
  /deep/.el-textarea .el-textarea__inner {
    width: 600px !important;
  }

  /deep/.el-checkbox-button__inner {
    width: 80px !important;
  }
}
.bcq-input {
  /deep/ input {
    width: 635px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #1a95eb;
  }
}
</style>
